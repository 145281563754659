input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #918c8e;
    transition: background-color 5000s ease-in-out 0s;
}

.header {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 200%;
    padding: 5%;
}

.textCenter {
    text-align: center;
    width: 100%;
}

.textRight {
    text-align: right;
    padding-right: 5px;
    width: 100%;
}

.textLeft {
    text-align: left;
    padding-left: 5px;
    width: 100%;
}

.myRow {
    padding-top: 1%;
    padding-bottom: 1%;
    width: 100%;
    opacity: 1;
}

.inputItem {
    position: relative;
    background-color: #F2EAED;
    height:  100%;
    min-height: 45px;
    width: 100%;
    box-shadow: 0 0 5px 7px #918c8e;
    border: transparent;
    border-radius: 10px;
    padding-left: 5%;
    color: #302e2f;
}

.__react_component_tooltip.type-dark {
    opacity: 0.9;
    color: black;
    background-color: #918c8e;
    border-radius: 10px;
    font-family:  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.__react_component_tooltip.type-dark.place-top:after{
    border-top-color: #918c8e;
}