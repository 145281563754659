.myNavbarItem {
    padding: 5px 5px 5px 5px ;
    font-size: large;
    position: relative;
    display: inline-block;
}

.myNavbarItem:hover {
    padding: 5px 5px 5px 5px ;
    font-size: large;
    position: relative;
    display: inline-block;
}

.myNavbar {
    height: 60px;
    top: 0;
    background-color: #F2EAED;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: absolute;
}

.navLeft {
    width: 80%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
}

.navRight {
    width: 20%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

