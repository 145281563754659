
button:focus {outline:0;}

.blue-colors {
    background-color: #A4A4BF; /* light-blue */
    color: #16235A; /* dark-blue */
    box-shadow: 0 0 5px 5px #2A3457; /* vintage-blue */
    border: #545c78; /* soft-blue */
}
.green-colors {
    background-color: #b7ba90; /* light-green */
    border: #888C46; /* vintage-green */
    color: #444623; /* dark-green */
}
.white-colors {
    background-color: #F2EAED; /* cream */
    border: #918c8e; /* for shadows */
    color: #302e2f; /* for text */
}

.myRedButton {
    position: relative;
    margin: 0;
    color: darkred;
    border-radius: 10px;
    border: solid darkred 1px;
    background-color: #ffdfdf;
    height: 50px;
    padding: 5px 20px 5px 20px;
    text-align: center;
    font-size: large;
    cursor: pointer;
    box-shadow: 0 0 0 white, 0 0 7px darkred;
}

.myRedButton:hover {
    background-color: indianred;
    font-weight: bold;
    box-shadow: none;
    color: #ffdfdf;

}

.myNavButton {
    position: relative;
    margin: 0;
    color: #545c78;
    border-radius: 10px;
    border: solid #545c78 1px;
    background-color: transparent;
    height: 50px;
    padding: 5px 20px 5px 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 0 white, 0 0 7px #545c78;
}

.myNavButton:hover {
    color: white;
    font-weight: bold;
    border-radius: 20px;
    text-shadow: 0 0 0 #302e2f, 0 0 7px #545c78;
    background-color: transparent;
    box-shadow: none;
}

.mySubmitButton {
    position: relative;
    background-color: #F2EAED;
    border: none;
    height: 50px;
    padding: 5px 40px 5px 40px;
    text-align: center;
    color: #444623;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.mySubmitButton:hover {
    animation: scale 3s infinite;
    background-color: #888C46;
    color: white;
}
@keyframes scale {
    0% { transform: scale(1); color: white; background-color: #b7ba90; border-radius: 5px}
    50% { transform: scale(1.2); color: white; background-color: #888C46; border-radius: 20px }
    90% { transform: scale(1); color: #444623; background-color: transparent; border-radius: 10px }
    100% { transform: scale(1); color: white; background-color: #b7ba90; border-radius: 5px }
}

.myArchiveButton {
    position: relative;
    background-color: #F2EAED;
    border: none;
    height: 50px;
    width: 50px;
    padding: 5px;
    text-align: center;
    color: #444623;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    animation: scale 5s infinite;
}

.myDropButton {
    position: relative;
    margin: 0;
    color: #2A3457;
    border-radius: 10px;
    border: solid #2A3457 1px;
    background-color: transparent;
    height: 50px;
    padding: 5px 20px 5px 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 0 white, 0 0 7px #2A3457;
}
.myDropButton:hover {
    color: white;
    font-weight: bold;
    border-radius: 20px;
    text-shadow: 0 0 0 black, 0 0 7px #2A3457;
    background-color: transparent;
    box-shadow: none;

}