

.alignM {
    vertical-align: middle;
}
.alignT {
    vertical-align: top;
}
.myCol-25 {
    position: relative;
    width: 21%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    margin: 1%;
}

.myCol-50 {
    position: relative;
    width: 45%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    margin: 1%;
}

.myCol-75 {
    position: relative;
    width: 71%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    margin: 1%;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="myCol-"] {
        width: 100%;
        text-align: center;
        padding: 0;
    }
    .appWidth {
        width: 90vw;
        left: 0;
        text-align: center;
    }
}

.app {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.appWidth {
    width: 60vw!important;
    min-width: 620px;
}