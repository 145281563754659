.blue-colors {
    background-color: #A4A4BF; /* light-blue */
    color: #16235A; /* dark-blue */
    box-shadow: 0 0 5px 5px #2A3457; /* vintage-blue */
    border: #545c78; /* soft-blue */
}
.green-colors {
    background-color: #b7ba90; /* light-green */
    border: #888C46; /* vintage-green */
    color: #444623; /* dark-green */
}
.white-colors {
    background-color: #F2EAED; /* cream */
    border: #918c8e; /* for shadows */
    color: #302e2f; /* for text */
}

.anyBackground {
    top: 0;
    width: 100%;
    position: absolute;
    padding: 60px 10px 10px 10px;
}

.signUpFormBackground {
    background-color: transparent;
}

.signInFormBackground {
    background-color: #b7ba90;
}

.forgetPasswordBackground {
    background-color: palevioletred;
}

.signUpFormBase {
    background-color: #fafae6;
}

.signInFormBase {
    background-color: lavender;
}

.signUpFormBase, .signInFormBase {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 10vh;
    border-radius: 5%;
}

.forgetPasswordBackground {
    position: absolute;
    background-color: white;
    width: 50%;
    left: 25%;
    top: 10vh;
}