.text {
    font-family:  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 100%;
    padding: 1rem;
}

.textC {
    font-family:  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    font-size: 100%;
    padding: 0.5rem;
}

.textBigC {
    font-family:  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    font-size: 200%;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
}

.link  {
    padding-left: 5px;
    padding-right: 5px;
    color: #2A3457!important;
    font-weight: bold;
}