input:focus {outline:0;}
button:focus {outline:0;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #918c8e;
    transition: background-color 5000s ease-in-out 0s;
}

.blue-colors {
    background-color: #A4A4BF; /* light-blue */
    color: #16235A; /* dark-blue */
    box-shadow: 0 0 5px 5px #2A3457; /* vintage-blue */
    border: #545c78; /* soft-blue */
}
.green-colors {
    background-color: #b7ba90; /* light-green */
    border: #888C46; /* vintage-green */
    color: #444623; /* dark-green */
}
.white-colors {
    background-color: #F2EAED; /* cream */
    border: #918c8e; /* for shadows */
    color: #302e2f; /* for text */
}


#button {
    cursor: pointer;
    outline:0;
}

.simple {
    background-color: #F2EAED;
    color: #302e2f;
}
.completed {
    background-color: #888C46;
    box-shadow: 0 0 5px 5px #888C46;
    color: #444623;
}

.simple, .completed {
    font-family: "Comic Sans MS";
    display: inline-block;
    margin: 2% 2% 2% 2%;
    border-radius: 20px 5px 5px 20px;
    border-width: 1%;
    width: 96%;
}

.hidden {
    display: none;
}

.textBlack > a {
    color: #302e2f;
}

.textWhite {
    color: #F2EAED;
}

.myIndex {
    text-align: right;
    padding-left: 20px;
    height: 100%;
    display: inline;
    min-height: 40px;
    vertical-align: middle;
}

.bothLists {
    justify-content: space-between;
    min-width: 600px;
    flex-wrap: nowrap;
}

.boxShadowTdy {
    border-radius: 10px;
    border-width: 20px;
    box-shadow: 0 0 5px 7px #A4A4BF;
    background: #A4A4BF;
    min-height: 40vh;
    max-width: 48%!important;
}
.boxShadowTmr {
    border-radius: 10px;
    border-width: 20px;
    box-shadow: 0 0 5px 7px #2A3457;
    background: #2A3457;
    min-height: 40vh;
    max-width: 48%!important;
}

@media only screen and  (max-width: 768px) {
    /* For mobile phones: */
    .bothLists {
        flex-wrap: wrap;
        position: relative;
    }
    .boxShadowTdy {
        position: relative;
        max-width: 93%!important;
        left: 2%;
        min-height: 20vh;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
    .boxShadowTmr {
        position: relative;
        max-width: 93%!important;
        left: 2%;
        min-height: 20vh;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
    .centering {
        justify-content: center;
        display: flex;
    }
}

/*Buttons in one todo row*/

.itemRow {
    display: flex;
    flex-wrap: nowrap!important;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.itemText {
    flex-grow: 4;
    padding-left: 5%;
    align-self: center;
    max-width: 60%;
}

.todoChangeForm {
    position: absolute;
    width: 100%;
    padding-left: 20%;
    font-family:  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

input {
    border: transparent!important;
    background-color: #fbf8f9;
    box-shadow: 0 0 5px 5px #fbf8f9;
    height: 30px!important;
    width: 100%;
}

.listButton {
    border: solid #2A3457 1px;
    min-height: 36px;
    height: 90%;
    width: 36px;
    background-color: transparent;
    border-radius: 18px;
    margin: 4px;
    align-self: center;
}

.listButton:hover{
     background:  #F2EAED;
     box-shadow: 0 0 4px 4px #888C46;
 }

.deleteButton {
    min-height: 36px;
    width: 36px;
    height: 90%;
    align-self: center;
    position: relative;
    color: darkred!important;
    border: solid darkred 1px;
    border-radius: 5px;
    background-color: #ffdfdf!important;
    margin: 5px 5px 5px 5px;
    font-size: large;
    box-shadow: 0 0 0 white, 0 0 7px darkred;
}

.deleteButtonSmall {
    min-height: 26px;
    width: 26px;
    height: 80%;
    align-self: center;
    position: relative;
    color: darkred!important;
    border: solid darkred 1px;
    border-radius: 5px;
    background-color: #ffdfdf!important;
    margin: 5px 5px 5px 5px;
    font-size: large;
    box-shadow: 0 0 0 white, 0 0 7px darkred;
}

.moveButton {
    width: 30px;
    height: 100%;
    min-height: 40px;
    text-align: center;
    border: none;
    align-self: center;
    background-color: transparent;
    font-family: "Arial Narrow";
    font-size: x-large;
    color: darkgray;
}
.moveButtonRight:hover {
    color: #2A3457!important;
    font-size: xx-large;
    font-weight: bolder;
}
.moveButtonLeft:hover {
    color: #A4A4BF!important;
    font-size: xx-large;
    font-weight: bolder;
}

.dropped {
    position: absolute;
    background-color:  #F2EAED;
    min-width: 200px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0px 8px 16px 0px #302e2f;
    z-index: 1;
    left: 60px;
}
.dropped a {
    color: #2A3457;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 0 10px 10px 10px;
}
.dropped a:hover {background-color: #A4A4BF; color: #F2EAED; font-weight: bold}

.historyText {
    align-self: center;
}

.modalForHistory {
    width: 40vw;
    min-width: 200px;
}

